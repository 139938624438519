import {shallowEqual, useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";
import {Skeleton} from "primereact/skeleton";
import { filterDevicesWithPatient } from "src/utils/generalMethods";
import {StoreState} from "src/reducers";
import {DeviceModel} from "src/models/device.model";
import {SCHEDULER_VIEW} from "src/models/scheduler.model";
import {ScrollingSidebar} from "src/components/general/ScrollingSidebar";
import {DeviceLanguage} from "src/components/general/settings/DeviceLanguage";
import {BroadcastMethodCard} from "src/components/general/settings/BroadcastMethodCard";
import {DeviceEarphoneVolume} from "src/components/general/settings/DeviceEarphoneVolume";
import {CameraOopIndicatiors} from "src/components/general/settings/CameraOopIndicatiors";
import {DeviceInitiationBehavior} from "src/components/general/settings/DeviceInitiationBehavior";
import {SchedulerCard} from "src/components/general/settings/SchedulerCard";
import {DeviceSettingsHeader} from "src/components/deviceView/DeviceSettingsHeader";

interface DeviceSettingsSelector {
    devices: DeviceModel[];
}

const DeviceSettingsSelector = ({ deviceObject }: StoreState): DeviceSettingsSelector => ({
    devices: filterDevicesWithPatient(deviceObject.devices)
})

const DeviceSettings = () => {
    const {t} = useTranslation();
    const {devices} = useSelector(DeviceSettingsSelector, shallowEqual)

    let skeletonObj = {
        title: t("Loading..."),
        subTitle: t(""),
        headerComponent: <Skeleton height="15rem" className="skeleton"/>,
        sections: [
            { name: "", element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/> },
            { name: "", element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/> },
            { name: "", element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/> }
        ],
    };

    let deviceObj = {
        title: t("List of Devices"),
        subTitle: t(""),
        headerComponent: <DeviceSettingsHeader />,
        sections: [
            { name: t("Communication"), element: <DeviceLanguage /> },
            { name: t("broadcastMethod"), element: <BroadcastMethodCard /> },
            { name: t("Earphone Volume"), element: <DeviceEarphoneVolume /> },
            { name: t("cameraOopIndicatiors"), element: <CameraOopIndicatiors /> },
            { name: t("deviceInitiationBehavior"), element: <DeviceInitiationBehavior /> },
            { name: t("Scheduler"), element: <SchedulerCard schedulerView={SCHEDULER_VIEW.DEVICE} /> },
        ],
    };

    return <ScrollingSidebar devices={devices} scrollingSidebarObj={devices?.length ? deviceObj : skeletonObj} />
}

export default DeviceSettings